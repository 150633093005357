<template>
  <dialog
    v-if="modelValue"
    ref="dialogEl"
    class="tw-shadow-xl tw-border tw-border-gray-200 tw-rounded tw-p-6 tw-bg-white tw-z-30 tw-absolute"
    @click="maybeClose"
    @keydown.esc="close"
  >
    <slot />
    <AButton
      round
      size="none"
      class="tw-absolute tw-top-5 tw-right-6 tw-w-8 tw-h-8"
      tabindex="-1"
      :title="i18n.generic.close"
      @click="close"
    >
      <icon-system-uicons-close class="tw-w-6 tw-h-6" />
    </AButton>
  </dialog>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'
import { inject, nextTick, ref, watch } from 'vue'
import type { I18N } from '../i18n'

const props = withDefaults(
  defineProps<{
    modelValue: boolean
    isModal?: boolean
  }>(),
  {
    isModal: false,
  },
)
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const dialogEl = ref<HTMLDialogElement>()

function close() {
  emit('update:modelValue', false)
}

function maybeClose(event: MouseEvent) {
  // when dialog is a modal the backdrop of it is still considered the dialog element
  // so the onClickOutside handler below won’t be triggered
  if (dialogEl.value) {
    const rect = dialogEl.value.getBoundingClientRect()
    const isInDialog =
      rect.top <= event.clientY &&
      event.clientY <= rect.top + rect.height &&
      rect.left <= event.clientX &&
      event.clientX <= rect.left + rect.width
    if (!isInDialog) {
      close()
    }
  }
}

onClickOutside(dialogEl, close)

watch(
  () => props.modelValue,
  async (isOpen) => {
    if (isOpen && !dialogEl.value) {
      await nextTick()
    }

    if (dialogEl.value) {
      if (isOpen) {
        if (props.isModal) {
          dialogEl.value.showModal()
        } else {
          dialogEl.value.show()
        }
      } else if (dialogEl.value.open) {
        dialogEl.value.close()
      }
    }
  },
)
const i18n = inject<I18N>('i18n') as I18N
</script>

<style lang="postcss" scoped>
dialog::backdrop {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px);
}
</style>
