<template>
  <audio
    :src="audiofile.streamUrl"
    controls
    class="tw-rounded-full"
    disableremoteplayback
    x-webkit-airplay="deny"
    preload="metadata"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { Audiofile } from '../types'

const props = defineProps<{
  audiofile: Audiofile
}>()

defineExpose({
  audiofile: computed(() => props.audiofile),
})
</script>

<style lang="postcss" scoped>
audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: theme('colors.green.100');
}

_::-webkit-full-page-media,
_:future,
:root audio::-webkit-media-controls-play-button,
_::-webkit-full-page-media,
_:future,
:root audio::-webkit-media-controls-panel {
  /* webkit doesn’t fare well with our custom background */
  background-color: theme('colors.gray.800');
}

audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
  display: none;
}
</style>
