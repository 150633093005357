import { Collection, ContentSequence } from '../types'

const collection: Collection<ContentSequence> = {
  1: {
    id: 1,
    sourceSequenceId: 1,
    relatedObject: {
      title: 'Ich höre was, was du nicht hörst mit Mattes',
      description:
        'Redakteur Mattes nimmt uns in diesem Beitrag in seinem Fantasie-Cabrio mit auf eine ganz besondere Reise in den Weltraum',
    },
  },
  2: {
    id: 2,
    sourceSequenceId: 2,
    relatedObject: {
      title: 'Schönes Liedmaterial',
      description: 'Dies, Das, Musik, verschiedene Künstler*innen',
    },
  },
  3: {
    id: 3,
    sourceSequenceId: 3,
    relatedObject: {
      title: 'Kate Bush Playlist',
      description: 'Eine Playlist von Musik von Kate Bush',
    },
  },
  4: {
    id: 4,
    sourceSequenceId: 4,
    relatedObject: {
      title: 'Hier geht’s um Fundskerle und deren Gelder',
      description: 'Dies Das Ananas',
    },
  },
}

export default collection
