import { ref, Ref, watchEffect } from 'vue'
import { Nullable } from '../types'

export function useAutoresizeTextarea(element: Ref<Nullable<HTMLTextAreaElement>>) {
  function resizeTextarea() {
    if (element.value) {
      element.value.style.height = 'auto'
      element.value.style.height = `calc(${element.value.scrollHeight}px + 10px)`
    }
  }

  watchEffect((onInvalidate) => {
    if (element.value) {
      resizeTextarea()
      element.value.addEventListener('input', resizeTextarea)
      onInvalidate(() => {
        element.value && element.value.removeEventListener('input', resizeTextarea)
      })
    }
  })
}

let id = 0
const idSeedMap: Record<string | number, string> = {}
export function useId(prefix: string, seed: string | number): Ref<string> {
  const newId = `${prefix}-${id++}`
  if (seed) {
    seed = `${prefix}-${seed}`
    if (!Object.prototype.hasOwnProperty.call(idSeedMap, seed)) {
      idSeedMap[seed] = newId
    }
    return ref(idSeedMap[seed])
  }

  return ref(newId)
}
