<template>
  <div ref="el" class="tw-flex" :data-csb-item="item.id" :data-csb-item-order="item.order">
    <template v-if="allowInsertion">
      <AInsertContentSequenceItem
        class="tw-top-0 -tw-translate-y-1/2"
        :title="i18n.sequenceItem.insertBefore"
        data-csb-insert-before
        @click="emit('insert', 'before')"
      />
      <AInsertContentSequenceItem
        class="tw-bottom-0 tw-translate-y-1/2"
        :title="i18n.sequenceItem.insertAfter"
        data-csb-insert-after
        @click="emit('insert', 'after')"
      />
    </template>
    <div
      v-if="level > 0"
      class="tw-flex-none tw-flex tw-justify-end"
      :style="{ width: `calc(${level} * var(--csb-indent-level-offset))` }"
    >
      <icon-system-uicons-pull-right class="tw-w-8 tw-h-8 tw-mr-4 tw-text-gray-300" />
    </div>
    <div class="tw-flex-1 tw-flex tw-flex-wrap tw-gap-6">
      <div class="tw-max-w-prose tw-flex-1">
        <div v-show="settings?.showAllSpeakerTexts" class="tw-mb-3">
          <AField
            :id="speakersTextId"
            class="tw-grow"
            :is-editing="isEditingSpeakersText"
            :allow-editing="allowModification"
            @edit="isEditingSpeakersText = $event"
          >
            <template #label>
              <ALabel>
                <icon-system-uicons-speech-bubble />
                {{ i18n.sequenceItem.speakersText }}
              </ALabel>
            </template>
            <AInput
              type="textarea"
              :model-value="item.speakersText"
              @update:model-value="emit('update:speakersText', $event)"
              @blur="isEditingSpeakersText = false"
            />
            <template #not-editing>
              <AMarkdown :content="item.speakersText" />
            </template>
          </AField>
        </div>
        <div v-show="settings?.showAllNotes && (allowModification || item.internalNote)">
          <AField
            :id="internalNoteId"
            class="tw-grow"
            :is-editing="isEditingInternalNote"
            :allow-editing="allowModification"
            @edit="isEditingInternalNote = $event"
          >
            <template #label>
              <ALabel>
                <icon-ph-info-light />
                {{ i18n.sequenceItem.internalNote }}
              </ALabel>
            </template>
            <AInput
              type="textarea"
              :model-value="item.internalNote"
              @update:model-value="emit('update:internalNote', $event)"
              @blur="isEditingInternalNote = false"
            />
            <template #not-editing>
              <AMarkdown :content="item.internalNote" />
            </template>
          </AField>
        </div>
      </div>
      <div v-if="item.audiofileId" class="tw-min-w-[200px] tw-max-w-[300px] tw-grow-[2] tw-ml-auto">
        <div class="tw-flex tw-items-center tw-bg-green-200 tw-rounded-full tw-max-w-full audio">
          <AEntityLoader
            v-if="api?.fetchAudiofile"
            v-slot="{ entity }"
            :entity-id="item.audiofileId"
            :fetch="api.fetchAudiofile"
          >
            <AAudiofile ref="audio" :audiofile="entity" class="tw-flex-auto tw-min-w-0" />
          </AEntityLoader>
          <AButton
            v-if="allowModification"
            class="tw-flex-none tw-mr-2"
            flavour="none"
            :title="i18n.generic.deassign"
            @click="emit('update:audiofileId', null)"
          >
            <icon-system-uicons-trash class="tw-w-5 tw-h-5" />
          </AButton>
        </div>
      </div>
      <div class="tw-flex tw-flex-col tw-relative tw-flex-none tw-w-12 tw-ml-auto">
        <AContentSequenceItemMenu
          v-if="allowModification"
          :item-id="item.id"
          :allow-copy="allowCopy"
          :allow-embed="item.nestedSequenceId === null"
          @delete="emit('delete')"
          @copy="emit('copy')"
          @unassign-schedule="emit('update:nestedSequenceId', null)"
          @show-audiofile-dialog="showAudiofileDialog = true"
          @show-sequence-dialog="showSequenceDialog = true"
        />
        <ADialog
          v-model="showAudiofileDialog"
          class="tw-w-[36rem] tw-top-[48px] tw-left-auto tw-right-0 tw-mt-2"
          @close="showAudiofileDialog = false"
          @cancel="showAudiofileDialog = false"
        >
          <AEmbedAudio
            :id="`csb-audio-dialog-${item.id}`"
            @update:model-value="emit('update:audiofileId', $event)"
            @close="showAudiofileDialog = false"
          />
        </ADialog>
        <ADialog
          v-model="showSequenceDialog"
          class="tw-w-[36rem] tw-top-[48px] tw-left-auto tw-right-0 tw-mt-2"
          @close="showSequenceDialog = false"
          @cancel="showSequenceDialog = false"
        >
          <AEmbedSequence
            :id="`csb-sequence-dialog-${item.id}`"
            :source-content-sequence-id="sourceContentSequenceId"
            @update:model-value="emit('update:nestedSequenceId', $event)"
            @close="showSequenceDialog = false"
          />
        </ADialog>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computedAsync, useElementBounding } from '@vueuse/core'
import { computed, inject, reactive, ref, watch } from 'vue'
import type { I18N } from '../i18n'
import type { API, Audiofile, ContentSequenceItem, Id, NullableId, Settings } from '../types'
import { isTemporaryId } from '../util/content-sequence'
import { useId } from '../util/dom'
import { estimateTimeToSpeak } from '../util/misc'
import { parseTime } from '../util/time'
import AAudiofile from './AAudiofile.vue'
import AButton from './AButton.vue'
import AContentSequenceItemMenu from './AContentSequenceItemMenu.vue'
import ADialog from './ADialog.vue'
import AEmbedAudio from './AEmbedAudio.vue'
import AEmbedSequence from './AEmbedSequence.vue'
import AEntityLoader from './AEntityLoader.vue'
import AField from './AField.vue'
import AInput from './AInput.vue'
import AInsertContentSequenceItem from './AInsertContentSequenceItem.vue'
import ALabel from './ALabel.vue'
import AMarkdown from './AMarkdown.vue'

const props = defineProps<{
  item: ContentSequenceItem
  sourceContentSequenceId: Id
  allowInsertion: boolean
  level: number
  orderChanges: number
}>()

const emit = defineEmits<{
  (e: 'copy'): void
  (e: 'delete'): void
  (e: 'insert', value: 'before' | 'after'): void
  (e: 'update:internalNote', value: string): void
  (e: 'update:speakersText', value: string): void
  (e: 'update:audiofileId', value: NullableId): void
  (e: 'update:nestedSequenceId', value: NullableId): void
}>()

const isEditingSpeakersText = ref(false)
const speakersTextId = useId('speakers-text', props.item.id)
const internalNoteId = useId('internal-note', props.item.id)
const isEditingInternalNote = ref(false)
const showAudiofileDialog = ref(false)
const showSequenceDialog = ref(false)
const allowModification = computed(
  () => props.item.sourceSequenceId === props.sourceContentSequenceId,
)

const api = inject<API>('api')
const settings = inject<Settings>('settings')
const i18n = inject<I18N>('i18n') as I18N

const allowCopy = computedAsync(async () => {
  if (!props.item.nestedSequenceId || !api || isTemporaryId(props.item.nestedSequenceId)) {
    return false
  } else {
    const sequence = await api.fetchContentSequence(props.item.nestedSequenceId)
    return !!sequence && sequence.sourceSequenceId !== props.sourceContentSequenceId
  }
}, false)

const el = ref<HTMLDivElement>()
const audio = ref()
const { top, height, update } = useElementBounding(el)
watch(() => props.orderChanges, update)

const musicRegex = /!music(?:\((?<duration>[\s\d:dhmins]+)\))?/

defineExpose({
  id: computed(() => props.item.id),
  position: computed(() => reactive({ top, height })),
  duration: computed(() => {
    if (audio.value) {
      return (audio.value.audiofile as Audiofile).duration
    }

    const match = props.item.internalNote.match(musicRegex)
    if (match?.groups?.duration) {
      return parseTime(match.groups.duration)
    } else if (props.item.speakersText) {
      return estimateTimeToSpeak(props.item.speakersText)
    } else {
      return 0
    }
  }),
  relativeStartTime: computed(() => props.item.relativeStartTime),
  type: computed(() => {
    if (props.item.internalNote.includes('!music')) {
      return 'music'
    } else if (audio.value) {
      return 'audio'
    } else if (props.item.speakersText) {
      return 'moderation'
    } else if (props.item.nestedSequenceId) {
      return 'embed'
    } else if (props.item.internalNote) {
      return 'note'
    } else {
      return 'default'
    }
  }),
})
</script>

<style lang="postcss" scoped>
_::-webkit-full-page-media,
_:future,
:root .audio {
  /* webkit forces it’s own border-radius so we adapt to it */
  border-radius: 6px;
}
</style>
