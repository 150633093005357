<template>
  <div ref="el" class="tw-flex tw-relative tw-px-6">
    <div class="tw-w-[2px] tw-bg-gray-200 tw-relative">
      <div class="tw-top-0" :class="capClasses"></div>
      <div class="tw-bottom-0" :class="capClasses"></div>
    </div>
    <ol>
      <slot :top="top" />
    </ol>
  </div>
</template>
<script lang="ts" setup>
import { useElementBounding } from '@vueuse/core'
import { ref } from 'vue'

const el = ref<HTMLDivElement>()
const { top } = useElementBounding(el)
const capClasses =
  'tw-h-2 tw-w-2 tw-bg-gray-200 tw-rounded-full tw-absolute -tw-left-1/2 -tw-translate-x-0.5'
</script>
