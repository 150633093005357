<template>
  <div class="tw-p-6 tw-bg-gray-100 tw-rounded" data-csb>
    <ACommandBar
      v-if="hasItems"
      v-model:showAllNotes="settings.showAllNotes"
      v-model:showAllSpeakerTexts="settings.showAllSpeakerTexts"
      :is-saving="isSaving"
      :num-undo-operations="undoStack.length - 1"
      :num-redo-operations="redoStack.length"
      @save="save"
      @undo="undo"
      @redo="redo"
    />
    <div :class="{ 'tw-flex': hasItems }">
      <div v-if="!hasItems" class="tw-flex tw-flex-1 tw-items-center tw-justify-center">
        <AButton
          flavour="indigo"
          size="medium"
          @click="batch(() => insertEmptySequenceItem(sequenceItems, contentSequenceId))"
        >
          {{ i18n.sequenceItem.insertFirst }}
        </AButton>
      </div>
      <ATimeline v-if="hasItems" v-slot="{ top }" class="tw-w-44 tw-flex-none">
        <template v-for="(item, index) in uniqueSequenceItemElements" :key="item.id">
          <ATimelineItem
            :item="item"
            :top-offset="top"
            :calculated-start-time="calculateStartTime(index)"
            :has-invalid-start-time="!!sequenceItemWithInvalidTimesMap[item.id]"
            @update:relative-start-time="updateSequenceItem(item.id, 'relativeStartTime', $event)"
          />
        </template>
        <span
          class="tw-font-semibold tw-text-sm tw-absolute tw-text-gray-700 -tw-bottom-1 tw-left-12 tw-ml-3"
          >{{ calculateStartTime(uniqueSequenceItemElements.length) }}</span
        >
      </ATimeline>
      <SortableList
        v-if="hasItems"
        ref="sortable"
        :list="sortableItems"
        :item-key="(element: Sortable<Sortable<ContentSequenceItem>[]>) => element.data[0].data.id"
        tag="ol"
        class="tw-flex-auto"
        data-csb-drag-target
        :options="{
          animation: 200,
          ghostClass: 'csb-drag-ghost',
          handle: '.csb-drag-handle',
        }"
        @update="updateListOrder"
      >
        <template #item="{ element: sortable }">
          <li
            class="tw-bg-white tw-shadow-lg tw-rounded tw-mb-3 last:tw-mb-0 tw-relative tw-group"
            :data-csb-drag-group="sortable.data[0].data.id"
          >
            <template v-for="({ data, level }, index) in sortable.data" :key="data.id">
              <hr v-if="index > 0" class="tw-col-span-2" />
              <div class="tw-p-6 tw-grid tw-gap-x-3 tw-gap-y-6 tw-grid-cols-[48px_minmax(0,1fr)]">
                <div class="tw-flex tw-flex-col">
                  <span
                    v-if="index === 0"
                    :title="i18n.generic.move"
                    class="tw-cursor-grab tw-flex tw-justify-center tw-aspect-square csb-drag-handle"
                    data-csb-drag-handle
                  >
                    <icon-system-uicons-drag-vertical class="tw-w-8 tw-h-8" />
                  </span>
                </div>
                <AContentSequenceItem
                  :ref="sequenceItemElements.set"
                  :source-content-sequence-id="contentSequenceId"
                  :item="data"
                  :level="level"
                  :order-changes="orderChanges"
                  :allow-insertion="index === 0"
                  @update:speakers-text="data.speakersText = $event"
                  @update:internal-note="data.internalNote = $event"
                  @update:audiofile-id="data.audiofileId = $event"
                  @update:nested-sequence-id="embedSchedule(data, $event)"
                  @insert="
                    batch(() => insertSequenceItem(sequenceItems, contentSequenceId, data, $event))
                  "
                  @copy="batch(() => copySequence(sequenceItems, data, contentSequenceId))"
                  @delete="batch(() => deleteSequenceItem(sequenceItems, data, contentSequenceId))"
                />
              </div>
            </template>
          </li>
        </template>
      </SortableList>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Sortable as SortableList } from 'sortablejs-vue3'
import { computed, ComputedRef, PropType, provide, Ref, ref, watch } from 'vue'

import { useDebouncedRefHistory, useDebounceFn, useTemplateRefsList } from '@vueuse/core'
import type { I18N } from '../i18n'
import type { API, ContentSequenceItem, Id, NullableId, Settings, Sortable } from '../types'
import {
  buildSortableContentSequenceItemList,
  consumeSequenceItems,
  copySequence,
  deleteSequenceItem,
  insertEmptySequenceItem,
  insertSequenceItem,
  reorderSequenceItems,
} from '../util/content-sequence'
import { asyncIterableToArray, unique, useLoading } from '../util/misc'
import { parseTime, secondsToTimeString } from '../util/time'
import ACommandBar from './ACommandBar.vue'
import AContentSequenceItem from './AContentSequenceItem.vue'
import ATimeline from './ATimeline.vue'

const props = defineProps({
  contentSequenceId: { type: Number as PropType<number>, required: true },
  api: { type: Object as PropType<API>, required: true },
  i18n: { type: Object as PropType<I18N>, required: true },
})

const settings: Ref<Settings> = ref({
  showAllNotes: true,
  showAllSpeakerTexts: true,
})

const sequenceItems: Ref<ContentSequenceItem[]> = ref([])
const sortableItems: ComputedRef<Sortable<Sortable<ContentSequenceItem>[]>[]> = computed(() =>
  Array.from(buildSortableContentSequenceItemList(sequenceItems.value, props.contentSequenceId)),
)
const hasItems = computed(() => sequenceItems.value.length > 0)
const { batch, clear, pause, resume, commit, undo, undoStack, redo, redoStack } =
  useDebouncedRefHistory(sequenceItems, {
    debounce: 500,
    deep: true,
  })
const sequenceItemElements = useTemplateRefsList<typeof AContentSequenceItem>()
const uniqueSequenceItemElements = computed(() => sequenceItemElements.value.filter(unique))
const sequenceItemWithInvalidTimesMap = computed(() => {
  const itemsWithInvalidTime = []
  let lastTime = 0
  for (const item of sequenceItems.value) {
    if (item.relativeStartTime === null) continue
    const itemTime = parseTime(item.relativeStartTime)
    if (itemTime < lastTime) {
      itemsWithInvalidTime.push(item)
    }
    lastTime = itemTime
  }
  return Object.fromEntries(itemsWithInvalidTime.map((item) => [item.id, item]))
})

function calculateStartTime(index: number): string {
  const items = uniqueSequenceItemElements.value.slice(0, index)
  return secondsToTimeString(
    items.reduce((total: number, item) => {
      const relativeStartTime = item.relativeStartTime ? parseTime(item.relativeStartTime) : total
      return relativeStartTime + item.duration
    }, 0),
  )
}

async function updateListOrder({ newIndex, oldIndex }: { newIndex: number; oldIndex: number }) {
  batch(() => {
    reorderSequenceItems(sequenceItems.value, props.contentSequenceId, oldIndex, newIndex)
  })
}

async function embedSchedule(item: ContentSequenceItem, scheduleId: NullableId) {
  if (scheduleId === null) {
    item.nestedSequenceId = null
  } else {
    const embeddedScheduleSequenceItems = await asyncIterableToArray(
      consumeSequenceItems(props.api.fetchContentSequenceItems, scheduleId),
    )
    const itemIndex = sequenceItems.value.indexOf(item)
    batch(() => {
      sequenceItems.value.splice(itemIndex + 1, 0, ...embeddedScheduleSequenceItems)
      item.nestedSequenceId = scheduleId
    })
  }
}

const { isLoading: isSaving, fn: save } = useLoading(async function save() {
  pause()
  try {
    await props.api.saveContentSequenceItems(
      // Make sure that only those sequence items are saved, which belong to our locally edited content sequence.
      // This shouldn’t matter, because we don’t allow edits on other sequence items,
      // but it’s nice to be on the safe-side.
      props.contentSequenceId,
      sequenceItems.value.filter((item) => item.sourceSequenceId === props.contentSequenceId),
    )
  } finally {
    resume()
  }
  await loadSequenceItems()
})

async function loadSequenceItems() {
  const newSequenceItems = await asyncIterableToArray(
    consumeSequenceItems(props.api.fetchContentSequenceItems, props.contentSequenceId),
  )
  clear()
  sequenceItems.value = newSequenceItems
  commit()
}

function updateSequenceItem(id: Id, key: keyof ContentSequenceItem, value: unknown) {
  const item = sequenceItems.value.find((item) => item.id === id)
  if (item) {
    item[key] = value as never
  }
}

const orderChanges = ref(0)
watch(
  () => sequenceItems.value.map((item) => [item.order, item.nestedSequenceId]),
  useDebounceFn(() => {
    orderChanges.value++
  }, 200),
)

provide('api', props.api)
provide('i18n', props.i18n)
provide('settings', settings)

loadSequenceItems()
</script>

<style lang="postcss" scoped>
.csb-drag-ghost {
  @apply tw-opacity-40;
  @apply tw-border-2;
  @apply tw-border-indigo-500;
}
</style>
