import { audiofiles, groups, sequenceItems, sequences } from '../fixtures'
import { Audiofile, ContentSequence, ContentSequenceItem, Group, Id } from '../types'
import { consumeSequenceItems, isTemporaryId } from '../util/content-sequence'

// prefill titles and descriptions with group data, like real APIs do automatically
Object.values(audiofiles).forEach((audiofile) => {
  const group = groups[audiofile.groupId]
  if (!group) return
  audiofile.title = audiofile.title.trim() || group.title.trim()
  audiofile.description = audiofile.description.trim() || group.description.trim()
})

const newId = (): Id => Math.random().toString(36).substring(2, 9)

class StoredValue<T> {
  _key: string
  _default: T

  constructor(key: string, defaultValue: T) {
    this._key = key
    this._default = defaultValue
  }

  get(): T {
    const data = localStorage.getItem(this._key)
    return data === null ? this._default : (JSON.parse(data) as T)
  }

  set(data: T): void {
    localStorage.setItem(this._key, JSON.stringify(data))
  }
}

const sequenceStore = new StoredValue<Record<Id, ContentSequence>>(
  'csb::content-sequence',
  sequences,
)

const sequenceItemStore = new StoredValue<Record<Id, ContentSequenceItem>>(
  'csb::content-sequence-items',
  sequenceItems,
)

function resetStores() {
  sequenceStore.set(sequences)
  sequenceItemStore.set(sequenceItems)
  if (typeof location !== 'undefined') {
    location.reload()
  }
}

export function useAPIConfiguration() {
  return {
    'Reset Stores': resetStores,
  }
}

export const fetchAudiofile = (id: Id): Promise<Audiofile> => Promise.resolve(audiofiles[id])

export const fetchGroup = (id: Id): Promise<Group> => Promise.resolve(groups[id])

export const fetchContentSequence = (id: Id): Promise<ContentSequence> =>
  Promise.resolve(sequences[id])

export async function* fetchContentSequenceItems(
  sequenceId: Id,
): AsyncIterable<ContentSequenceItem> {
  for (const item of Object.values(sequenceItemStore.get())) {
    if (item.sequenceId === sequenceId) {
      yield item
    }
  }
}

export async function* searchAudiofiles(
  term: string,
  options?: { page?: number; pageSize?: number },
): AsyncIterable<Audiofile> {
  term = term.toLowerCase()
  const page = options?.page ?? 1
  const pageSize = options?.pageSize ?? 10
  const offset = (page - 1) * pageSize
  let hits = 0
  let emitted = 0

  for (const audiofile of Object.values(audiofiles)) {
    const group = groups?.[audiofile.groupId]
    if (
      audiofile.title.toLowerCase().includes(term) ||
      audiofile.description.toLowerCase().includes(term) ||
      group?.title?.toLowerCase()?.includes(term) ||
      group?.description?.toLowerCase()?.includes(term)
    ) {
      if (offset > hits++) continue
      yield audiofile
      if (++emitted > page) break
    }
  }
}

export async function* searchContentSequences(
  term: string,
  filters: { isEmbeddableIn: Id },
  options?: { page?: number; pageSize?: number },
): AsyncIterable<ContentSequence> {
  term = term.toLowerCase()
  const page = options?.page ?? 1
  const pageSize = options?.pageSize ?? 10
  const offset = (page - 1) * pageSize
  let hits = 0
  let emitted = 0

  const skipSequences = new Set<Id>()
  for await (const item of await consumeSequenceItems(
    fetchContentSequenceItems,
    filters.isEmbeddableIn,
  )) {
    skipSequences.add(item.sequenceId)
    skipSequences.add(item.sourceSequenceId)
    if (item.nestedSequenceId) {
      skipSequences.add(item.nestedSequenceId)
    }
  }

  for (const sequence of Object.values(sequences)) {
    if (!sequence.relatedObject) continue
    if (skipSequences.has(sequence.id)) continue
    if (
      sequence.relatedObject.title.toLowerCase().includes(term) ||
      sequence.relatedObject.description.toLowerCase().includes(term)
    ) {
      if (offset > hits++) continue
      yield sequence
      if (++emitted > page) break
    }
  }
}

export async function saveContentSequenceItems(
  contentSequenceId: Id,
  items: ContentSequenceItem[],
): Promise<void> {
  const storedSequences = sequenceStore.get()
  const storedSequenceItems = sequenceItemStore.get()
  const newSequencesMap: Record<Id, ContentSequence> = {}

  function getOrCreateSequence(tempId: Id): ContentSequence {
    // The item’s sequenceId might be temporary, if they were copied from another sequence.
    // In this case we want to create a new sequence for the item and cache it in our local map,
    // so that we recognize the same temporary id and assign the same sequence.
    if (!newSequencesMap[tempId]) {
      const id = newId()
      newSequencesMap[tempId] = { id, sourceSequenceId: contentSequenceId, relatedObject: null }
      storedSequences[id] = newSequencesMap[tempId]
    }
    return newSequencesMap[tempId]
  }

  // handle new and updated elements
  for (const item of items) {
    if (isTemporaryId(item.id)) {
      item.id = newId()
    }
    if (item.nestedSequenceId && isTemporaryId(item.nestedSequenceId)) {
      item.nestedSequenceId = getOrCreateSequence(item.nestedSequenceId).id
    }
    if (isTemporaryId(item.sequenceId)) {
      item.sequenceId = getOrCreateSequence(item.sequenceId).id
    }
    storedSequenceItems[item.id] = item
  }

  // handle deleted elements
  const existingItems = Object.values(storedSequenceItems).filter(
    (item) => item.sequenceId === contentSequenceId,
  )
  for (const existingItem of existingItems) {
    const doesItemStillExist = items.find((item) => item.id === existingItem.id)
    if (!doesItemStillExist) {
      delete storedSequenceItems[existingItem.id]
    }
  }

  sequenceStore.set(storedSequences)
  sequenceItemStore.set(storedSequenceItems)
  await new Promise((resolve) => setTimeout(resolve, 1000))
}
