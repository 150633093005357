import { Audiofile, Collection } from '../types'

const collection: Collection<Audiofile> = {
  13: {
    id: 13,
    title: '',
    description: '',
    duration: 212,
    groupId: 8,
    streamUrl: 'https://produktion.lohro.de/api/v1/audiofiles/13/stream',
  },
}

export default collection
