<template>
  <div class="tw-max-w-7xl tw-px-6 tw-mx-auto tw-my-12">
    <div class="tw-flex tw-flex-wrap tw-gap-x-6 tw-gap-y-3 tw-mb-6">
      <label class="tw-flex tw-gap-3 tw-items-center">
        <span class="tw-font-semibold">Locale</span>
        <select v-model="locale" class="tw-py-2 tw-px-4 tw-rounded tw-bg-gray-100">
          <template v-for="name in localeNames" :key="name">
            <option :value="name">{{ name }}</option>
          </template>
        </select>
      </label>
      <label class="tw-flex tw-gap-3 tw-items-center">
        <span class="tw-font-semibold">API</span>
        <select v-model="api" class="tw-py-2 tw-px-4 tw-rounded tw-bg-gray-100">
          <template v-for="name in apiNames" :key="name">
            <option :value="name">{{ name }}</option>
          </template>
        </select>
      </label>
      <label class="tw-flex tw-gap-3 tw-items-center">
        <span class="tw-font-semibold">Sequence ID</span>
        <input
          v-model.number="contentSequenceId"
          type="number"
          min="1"
          step="1"
          class="tw-py-2 tw-px-4 tw-rounded tw-bg-gray-100"
        />
      </label>
      <div class="tw-basis-full tw-h-0" />
      <template v-if="apiConfiguration">
        <template v-for="(value, key) in apiConfiguration" :key="key">
          <label v-if="typeof value === 'string'" class="tw-flex tw-gap-3 tw-items-center">
            <span class="tw-font-semibold">{{ key }}</span>
            <input
              v-model="apiConfiguration[key]"
              type="text"
              class="tw-py-2 tw-px-4 tw-rounded tw-bg-gray-100"
            />
          </label>
          <AButton v-else-if="typeof value === 'function'" @click="() => value()">
            {{ key }}
          </AButton>
        </template>
      </template>
    </div>

    <ContentSequenceBuilder
      v-if="showBuilder"
      :content-sequence-id="contentSequenceId"
      :api="currentAPI"
      :i18n="currentLocale"
    />
  </div>
</template>

<script setup lang="ts">
import { useStorage, watchDebounced } from '@vueuse/core'
import { computed, nextTick, ref, watch } from 'vue'
import * as apis from './apis'
import AButton from './components/AButton.vue'
import ContentSequenceBuilder from './components/AContentSequenceBuilder.vue'
import * as locales from './i18n'

const api = useStorage<keyof typeof apis>('csb::api', 'fixturesAPI')
const apiNames = computed(() => Object.keys(apis))
const currentAPI = computed(() => apis[api.value])
const locale = useStorage<keyof typeof locales>('csb::locale', 'en')
const localeNames = computed(() => Object.keys(locales))
const currentLocale = computed(() => locales[locale.value])
const contentSequenceId = useStorage('csb::content-sequence-id', 1)
const showBuilder = ref(true)
const apiConfigurationStore = useStorage<Record<string, Record<string, unknown>>>(
  'csb::api-configuration',
  {},
)
const apiConfiguration = ref<Record<string, unknown> | null>(null)

function loadAPIConfiguration() {
  apiConfiguration.value = apis[api.value].useAPIConfiguration()
  Object.assign(apiConfiguration.value, apiConfigurationStore.value[api.value])
}

async function reinitializeContentSequenceBuilder() {
  showBuilder.value = false
  await nextTick()
  showBuilder.value = true
}

watch([api, locale, contentSequenceId], async ([newApi], [oldApi]) => {
  if (newApi !== oldApi) loadAPIConfiguration()
  await reinitializeContentSequenceBuilder()
})

watch(
  apiConfiguration,
  async (newApiConfiguration) => {
    apiConfigurationStore.value[api.value] = newApiConfiguration ?? {}
  },
  { deep: true },
)

watchDebounced(apiConfiguration, reinitializeContentSequenceBuilder, { debounce: 500, deep: true })

loadAPIConfiguration()
</script>

<style>
body {
  overflow-y: scroll;
}
</style>
