<template>
  <component
    :is="is"
    class="tw-inline-flex tw-font-semibold tw-items-center tw-gap-1 tw-select-none tw-transition-all tw-justify-center tw-origin-center"
    :class="cssClasses"
    :disabled="disabled"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    round?: boolean
    disabled?: boolean
    size?: 'none' | 'small' | 'medium' | 'large'
    flavour?: 'none' | 'gray' | 'gray-blend' | 'indigo' | 'indigo-blend'
    is?: string | object
  }>(),
  {
    round: false,
    size: 'small',
    flavour: 'gray',
    is: 'button',
  },
)

const cssClasses = computed(() => {
  return {
    'tw-rounded': !props.round,
    'tw-rounded-full': props.round,
    'tw-text-sm tw-px-2 tw-py-1': props.size === 'small',
    'tw-text-base tw-px-4 tw-py-2': props.size === 'medium',
    'tw-text-2xl tw-px-6 tw-py-2': props.size === 'large',
    'tw-bg-gray-100 tw-text-black/75': props.flavour === 'gray',
    'hover:tw-bg-gray-200': props.flavour === 'gray' && !props.disabled,
    'tw-bg-indigo-200': props.flavour === 'indigo',
    'hover:tw-bg-indigo-500 hover:tw-text-white': props.flavour === 'indigo' && !props.disabled,
    'hover:tw-bg-gray-100': props.flavour === 'gray-blend' && !props.disabled,
    'hover:tw-text-indigo-500': props.flavour === 'indigo-blend' && !props.disabled,
    'tw-cursor-deny tw-opacity-50 tw-grayscale-[30%] tw-saturate-[75%]': props.disabled,
    'tw-cursor-pointer': !props.disabled,
  }
})
</script>
