<template>
  <AEmbed
    :id="id"
    :get-title="getTitle"
    :get-description="getDescription"
    :search="searchContentSequences"
    :texts="{
      placeholder: i18n.sequence.searchPlaceholder,
      searchHint: i18n.sequence.searchHint,
      selectLabel: i18n.sequence.select,
    }"
    @update:model-value="emit('update:modelValue', $event)"
    @close="emit('close')"
  >
    <template #label>
      <icon-system-uicons-pull-right class="tw-w-6 tw-h-6" />
      {{ i18n.sequence.selectLabel }}
    </template>
  </AEmbed>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script lang="ts" setup>
import { inject } from 'vue'

import type { I18N } from '../i18n'
import type { API, ContentSequence, Id } from '../types'
import AEmbed from './AEmbed.vue'

const props = defineProps<{
  id: string
  sourceContentSequenceId: Id
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: Id): void
  (e: 'close'): void
}>()

const api = inject<API>('api') as API
const i18n = inject<I18N>('i18n') as I18N

const getTitle = (item: ContentSequence) => item.relatedObject?.title ?? ''
const getDescription = (item: ContentSequence) => item.relatedObject?.description ?? ''

async function* searchContentSequences(
  term: string,
  options?: { pageSize?: number; page?: number },
): AsyncIterable<ContentSequence> {
  yield* await api.searchContentSequences(
    term,
    { isEmbeddableIn: props.sourceContentSequenceId },
    options,
  )
}
</script>
