<template>
  <slot v-if="entity" :entity="entity"></slot>
  <slot v-if="entity === null" name="trigger" :is-loading="isLoading" :load="loadEntity"></slot>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import { Id, Nullable } from '../types'

const props = defineProps<{
  // TODO: not sure if we can implement a generic AEntityLoader<T>
  //       component that would pass down type information to the slot
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetch: (id: Id) => Promise<any>
  entityId: Id
}>()

const entity = ref(null)
const isLoading = ref<boolean>(false)
const error = ref<Nullable<Error>>(null)

async function loadEntity() {
  try {
    isLoading.value = true
    entity.value = await props.fetch(props.entityId)
  } catch (e) {
    if (e instanceof Error) {
      error.value = e
    } else {
      error.value = new Error(String(e))
    }
  } finally {
    isLoading.value = false
  }
}

loadEntity()

watch(() => [props.entityId, props.fetch], loadEntity)
</script>
