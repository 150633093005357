<template>
  <div
    class="tw-sticky tw-top-6 tw-z-30 tw-mb-9 tw-mx-auto tw-grid tw-grid-cols-[minmax(0,1fr)_min-content_minmax(0,1fr)] tw-gap-6"
  >
    <div class="tw-flex tw-items-center tw-justify-end"></div>
    <div
      class="tw-bg-indigo-100 tw-rounded-full tw-flex tw-items-stretch tw-text-2xl tw-overflow-hidden tw-shadow-lg tw-border-blue-400 tw-border"
    >
      <div class="tw-flex">
        <ASwitch
          :model-value="showAllNotes"
          :title="showAllNotes ? i18n.builder.hideNotes : i18n.builder.showNotes"
          @update:model-value="emit('update:showAllNotes', $event)"
        >
          <span class="tw-sr-only">{{ i18n.builder.showNotes }}</span>
          <icon-ph-info-light />
        </ASwitch>
        <ASwitch
          :model-value="showAllSpeakerTexts"
          :title="
            showAllSpeakerTexts ? i18n.builder.hideSpeakerTexts : i18n.builder.showSpeakerTexts
          "
          @update:model-value="emit('update:showAllSpeakerTexts', $event)"
        >
          <span class="tw-sr-only">{{ i18n.builder.showSpeakerTexts }}</span>
          <icon-system-uicons-speech-bubble />
        </ASwitch>

        <AButton
          size="large"
          flavour="indigo"
          round
          :disabled="isSaving || numUndoOperations === 0"
          @click="emit('save')"
        >
          <icon-system-uicons-cloud-upload v-if="!isSaving" />
          <icon-system-uicons-loader v-else class="tw-animate-spin" />
          <span class="tw-text-lg">{{ i18n.builder.save }}</span>
        </AButton>

        <AButton
          round
          flavour="indigo-blend"
          size="none"
          class="tw-p-3 csb-number-badge csb-right"
          :data-number-badge="numUndoOperations > 0 ? numUndoOperations : null"
          :disabled="numUndoOperations === 0"
          :title="i18n.builder.undo"
          data-csb-undo
          @click="emit('undo')"
        >
          <icon-system-uicons-undo />
        </AButton>
        <AButton
          round
          flavour="indigo-blend"
          size="none"
          class="tw-p-3 csb-number-badge csb-left"
          :data-number-badge="numRedoOperations > 0 ? numRedoOperations : null"
          :disabled="numRedoOperations === 0"
          :title="i18n.builder.redo"
          data-csb-redo
          @click="emit('redo')"
        >
          <icon-system-uicons-redo />
        </AButton>
      </div>
    </div>
    <div class="tw-flex tw-items-center tw-justify-start"></div>
  </div>
</template>

<script lang="ts" setup>
import { inject } from 'vue'
import type { I18N } from '../i18n'
import ASwitch from './ASwitch.vue'

defineProps<{
  showAllNotes: boolean
  showAllSpeakerTexts: boolean
  isSaving: boolean
  numUndoOperations: number
  numRedoOperations: number
}>()
const emit = defineEmits<{
  (e: 'update:showAllNotes', value: boolean): void
  (e: 'update:showAllSpeakerTexts', value: boolean): void
  (e: 'save'): void
  (e: 'undo'): void
  (e: 'redo'): void
}>()
const i18n = inject<I18N>('i18n') as I18N
</script>

<style lang="postcss" scoped>
.csb-number-badge {
  @apply tw-relative;
}

.csb-number-badge[data-number-badge]::before {
  @apply tw-absolute tw-bottom-1 tw-pointer-events-none tw-text-xs tw-rounded-full tw-px-1 tw-min-w-[16px] tw-bg-indigo-500 tw-text-white tw-flex tw-items-center tw-justify-center tw-z-10;
  content: attr(data-number-badge);
}

.csb-number-badge[data-number-badge]::after {
  @apply tw-absolute tw-left-1/2 tw-top-1/2 tw-w-10 tw-h-10 -tw-translate-y-1/2 -tw-translate-x-1/2 tw-border tw-rounded-full tw-border-indigo-300;
  content: '';
}

.csb-left[data-number-badge]::before {
  @apply tw-left-1;
}

.csb-right[data-number-badge]::before {
  @apply tw-right-1;
}
</style>
