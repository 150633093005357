import { Collection, ContentSequenceItem } from '../types'

const collection: Collection<ContentSequenceItem> = {
  1: {
    id: 1,
    sequenceId: 1,
    sourceSequenceId: 1,
    order: 0,
    nestedSequenceId: null,
    audiofileId: null,
    relativeStartTime: null,
    internalNote:
      "Ich höre was, was du nicht hörst mit Mattes: Redakteur Mattes nimmt uns in diesem Beitrag in seinem Fantasie-Cabrio mit auf eine ganz besondere Reise in ferne Welten im Weltall und wandert dort durch einen Mix aus Großstadt und Dschungel. Neugierig? Na dann: Kopfhörer auf, Augen zu und los geht's!",
    speakersText: '',
  },
  2: {
    id: 2,
    sequenceId: 1,
    sourceSequenceId: 1,
    order: 1,
    nestedSequenceId: null,
    audiofileId: 13,
    relativeStartTime: null,
    internalNote: '',
    speakersText: '',
  },
  3: {
    id: 3,
    sequenceId: 1,
    sourceSequenceId: 1,
    order: 2,
    nestedSequenceId: null,
    audiofileId: null,
    relativeStartTime: null,
    internalNote: '',
    speakersText:
      'Jeder empfindet Musik anders. Unser Redakteur Mattes erzählt euch heute seine Fantasien und Eindrücke zu einem Song, den er dabei gerade zum erstenmal hört.',
  },
  4: {
    id: 4,
    sequenceId: 1,
    sourceSequenceId: 1,
    order: 3,
    nestedSequenceId: null,
    audiofileId: null,
    relativeStartTime: null,
    internalNote: '',
    speakersText:
      'Das war eine interessante Reise durch Mattes Fantasien zu dem Song "Palm of my Hand" von ZHU. Vielleicht hört ihr ja jetzt etwas anderes aus dem Song heraus. Hier ist ZHU mit "Palm of my Hand"',
  },
  5: {
    id: 5,
    sequenceId: 1,
    sourceSequenceId: 1,
    order: 4,
    nestedSequenceId: 2,
    audiofileId: null,
    relativeStartTime: null,
    internalNote: '',
    speakersText: 'Eingebetteter Beitrag',
  },
  6: {
    id: 6,
    sequenceId: 2,
    sourceSequenceId: 2,
    order: 0,
    nestedSequenceId: 3,
    audiofileId: null,
    relativeStartTime: null,
    internalNote: '',
    speakersText: 'Und jetzt kommt noch ein **schönes** Lied',
  },
  7: {
    id: 7,
    sequenceId: 1,
    sourceSequenceId: 1,
    order: 5,
    nestedSequenceId: null,
    audiofileId: null,
    relativeStartTime: null,
    internalNote: '',
    speakersText: 'Moderation nach dem eingebetteten Beitrag',
  },
  8: {
    id: 8,
    sequenceId: 3,
    sourceSequenceId: 3,
    order: 0,
    nestedSequenceId: null,
    audiofileId: null,
    relativeStartTime: null,
    internalNote: '!music(4m29s)\n\nKate Bush - Wuthering Heights',
    speakersText: '',
  },
  9: {
    id: 9,
    sequenceId: 4,
    sourceSequenceId: 4,
    order: 0,
    nestedSequenceId: null,
    audiofileId: null,
    relativeStartTime: null,
    internalNote: 'Ein bisher nicht eingebetteter Beitrag',
    speakersText: '',
  },
}

export default collection
