export function getCookie(key: string): string | undefined {
  if (typeof document === 'undefined') return undefined
  const cookie = (document?.cookie ?? '')
    .split(';')
    .map((cookieStr) => cookieStr.trim())
    .map((cookieStr) => {
      const [key, value] = cookieStr.split('=', 2)
      return { key, value: decodeURIComponent(value) }
    })
    .find((cookie) => cookie.key === key)
  return cookie?.value
}
