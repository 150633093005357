export function secondsToDurationString(seconds: number): string {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.round(seconds % 60)
  return `${h ? h + 'h ' : ''}${m ? m + 'min ' : ''}${s ? s + 's' : ''}`
}

export function secondsToTimeString(seconds: number): string {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.round(seconds % 60)
  return [h, m, s].map((n) => n.toString().padStart(2, '0')).join(':')
}

function arrayPadLeft<T>(array: T[], maxLength: number, fillValue: T): T[] {
  return Array(Math.max(0, maxLength - array.length))
    .fill(fillValue)
    .concat(array)
}

export function parseTime(time: string): number {
  let [days, hours, minutes, seconds] = Array(4).fill(0)

  if (time.includes(':')) {
    ;[days, hours, minutes, seconds] = arrayPadLeft<number | string>(time.split(':'), 4, 0)
  } else {
    // parses strings like: "3m32", "3 h 2s ", "9d2h3min1"
    const format =
      /^\s*(?:(?<days>\d+)\s*d)?\s*(?:(?<hours>\d+)\s*h)?\s*(?:(?<minutes>\d+)\s*m(?:in)?)?\s*(?:(?<seconds>\d+)\s*s?)?\s*$/
    ;({ days, hours, minutes, seconds } = time.match(format)?.groups ?? {})
  }

  return (
    parseInt(days || 0) * 24 * 60 * 60 +
    parseInt(hours || 0) * 60 * 60 +
    parseInt(minutes || 0) * 60 +
    parseInt(seconds || 0)
  )
}
