<template>
  <li
    class="tw-absolute tw-max-w-full tw-left-0 tw-z-10 tw-group tw-text-gray-700"
    :class="{ 'tw-transition-transform': isReady }"
    :style="{ transform: `translateY(${item.position.top - topOffset}px)` }"
  >
    <div class="tw-flex tw-relative -tw-top-2">
      <div
        class="tw-w-12 tw-h-12 tw-flex-none tw-rounded-full tw-translate-x-px tw-relative -tw-left-px tw-border tw-outline tw-outline-4 tw-outline-gray-100 tw-text-black/75 tw-flex tw-items-center tw-justify-center tw-p-1 tw-text-2xl"
        :class="{
          'tw-bg-red-100 tw-border-red-300': item.type === 'default',
          'tw-bg-green-100 tw-border-green-300': item.type === 'audio',
          'tw-bg-amber-100 tw-border-amber-300': item.type === 'music',
          'tw-bg-white tw-border-gray-300': ['moderation', 'note', 'embed'].includes(item.type),
        }"
      >
        <icon-system-uicons-speech-bubble v-if="item.type === 'moderation'" />
        <icon-ph-info-light v-else-if="item.type === 'note'" class="tw-ml-px" />
        <icon-system-uicons-pull-right v-else-if="item.type === 'embed'" class="tw-ml-1" />
        <icon-system-uicons-audio-wave v-else-if="item.type === 'audio'" />
        <icon-ph-music-notes-simple-light v-else-if="item.type === 'music'" />
        <icon-ph-circle-wavy-question-light v-else />
      </div>
      <div class="tw-text-lg tw-flex tw-flex-col tw-p-3 -tw-mt-1">
        <AField
          :id="`start-time-${item.id}`"
          :is-editing="isEditingStartTime"
          class="tw-flex tw-items-center tw-h-8 tw-mb-0"
          @edit="isEditingStartTime = true"
        >
          <template #not-editing-pre>
            <p
              class="tw-text-sm tw-font-bold tw-px-2 -tw-mx-2 tw-rounded"
              :class="{
                'tw-bg-rose-600 tw-text-white': hasInvalidStartTime,
                'tw-text-indigo-500': !hasInvalidStartTime && item.relativeStartTime,
              }"
              :aria-label="i18n.sequenceItem.relativeStartTime"
              :title="hasInvalidStartTime ? i18n.sequenceItem.relativeStartTimeIssue : undefined"
            >
              {{ item.relativeStartTime ?? calculatedStartTime }}
            </p>
          </template>
          <template #label>
            <span class="tw-sr-only">{{ i18n.sequenceItem.relativeStartTime }}</span>
          </template>
          <template #default="{ id }">
            <input
              :id="id"
              ref="relativeStartTimeInput"
              type="text"
              class="tw-bg-transparent tw-text-sm tw-w-[70px]"
              :value="item.relativeStartTime ?? calculatedStartTime"
              @change="updateRelativeStartTime"
              @blur="isEditingStartTime = false"
            />
          </template>
        </AField>

        <p class="tw-text-sm tw-text-gray-600">{{ secondsToDurationString(item.duration) }}</p>
      </div>
    </div>
  </li>
</template>

<script lang="ts" setup>
import { useTimeout } from '@vueuse/core'
import { inject, nextTick, ref, watch } from 'vue'
import { I18N } from '../i18n'
import { Id, Nullable } from '../types'
import { parseTime, secondsToDurationString, secondsToTimeString } from '../util/time'
import AField from './AField.vue'

type TimelineItem = {
  id: Id
  duration: number
  relativeStartTime: Nullable<number>
  type: 'audio' | 'moderation' | 'music' | 'note' | 'embed' | 'default'
  position: { top: number; height: number }
}

defineProps<{
  item: TimelineItem
  topOffset: number
  calculatedStartTime: string
  hasInvalidStartTime: boolean
}>()

const emit = defineEmits<{
  (e: 'update:relativeStartTime', time: Nullable<string>): void
}>()

const isReady = useTimeout(1000)
const isEditingStartTime = ref(false)
const relativeStartTimeInput = ref<HTMLInputElement>()

watch(isEditingStartTime, async (isEditingStartTime) => {
  if (isEditingStartTime) {
    await nextTick()
    relativeStartTimeInput.value?.focus?.()
  }
})

function updateRelativeStartTime(event: Event): void {
  const value = ((event.target as HTMLInputElement).value ?? '').trim()
  emit('update:relativeStartTime', value ? secondsToTimeString(parseTime(value)) : null)
}

const i18n = inject<I18N>('i18n') as I18N
</script>
