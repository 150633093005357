import { ContentSequenceItem, Id, Sortable } from '../types'
import { sortBy } from './misc'

function* orderSequenceItems(
  sequenceItems: ContentSequenceItem[],
  rootContentSequenceId: Id,
): Iterable<ContentSequenceItem> {
  const itemsForCurrentSequence = getSortedSequenceItems(sequenceItems, rootContentSequenceId)
  for (const item of itemsForCurrentSequence) {
    yield item
    if (item.nestedSequenceId !== null) {
      yield* orderSequenceItems(sequenceItems, item.nestedSequenceId)
    }
  }
}

function newSortable<T>(order: number, data: T, level = 0) {
  return { level, order, data }
}

function setSequenceItemsOrder(items: ContentSequenceItem[], rootSequenceId?: Id) {
  if (rootSequenceId) {
    items = items.filter((item) => item.sequenceId === rootSequenceId)
  }
  items.forEach((item, index) => {
    item.order = index
  })
}

function getSortedSequenceItems(
  sequenceItems: ContentSequenceItem[],
  rootContentSequenceId: Id,
): ContentSequenceItem[] {
  return sortBy(
    sequenceItems.filter((item) => item.sequenceId === rootContentSequenceId),
    (item) => item.order,
  )
}

export function* buildSortableContentSequenceItemList(
  sequenceItems: ContentSequenceItem[],
  rootContentSequenceId: number,
): Iterable<Sortable<Sortable<ContentSequenceItem>[]>> {
  sequenceItems = Array.from(orderSequenceItems(sequenceItems, rootContentSequenceId))
  const sequenceHierarchy: Id[] = []
  let index = 0
  let bucket: Sortable<Sortable<ContentSequenceItem>[]> = newSortable(index++, [])

  for (const item of sequenceItems) {
    if (sequenceHierarchy.includes(item.sequenceId)) {
      sequenceHierarchy.splice(sequenceHierarchy.indexOf(item.sequenceId) + 1)
    } else {
      sequenceHierarchy.push(item.sequenceId)
    }

    const nestedSortable = newSortable(item.order, item, sequenceHierarchy.length - 1)
    if (item.sequenceId === rootContentSequenceId) {
      if (bucket.data.length) yield bucket
      bucket = newSortable(index++, [])
      bucket.data.push(nestedSortable)
    } else {
      bucket.data.push(nestedSortable)
    }
  }

  if (bucket.data.length > 0) {
    yield bucket
  }
}

const TEMP_ID_PREFIX = 'temp-id-'

function createTemporaryId(): string {
  return TEMP_ID_PREFIX + Math.random().toString(36).substring(2, 9)
}

export function isTemporaryId(id: Id): boolean {
  return id.toString().startsWith(TEMP_ID_PREFIX)
}

function newSequenceItem(
  item: Partial<ContentSequenceItem> & Pick<ContentSequenceItem, 'sequenceId'>,
): ContentSequenceItem {
  return {
    id: createTemporaryId(),
    sourceSequenceId: item.sourceSequenceId ?? item.sequenceId,
    order: item.order ?? 0,
    nestedSequenceId: null,
    audiofileId: null,
    relativeStartTime: null,
    internalNote: '',
    speakersText: '',
    ...item,
  }
}

export function insertEmptySequenceItem(
  sequenceItems: ContentSequenceItem[],
  targetSequenceId: Id,
  index = 0,
) {
  const newItem: ContentSequenceItem = newSequenceItem({ sequenceId: targetSequenceId })
  sequenceItems.splice(index, 0, newItem)
  setSequenceItemsOrder(sequenceItems, targetSequenceId)
}

export function insertSequenceItem(
  sequenceItems: ContentSequenceItem[],
  targetSequenceId: Id,
  item: ContentSequenceItem,
  placement: 'before' | 'after',
) {
  const itemIndex = sequenceItems.indexOf(item)
  const newItem: ContentSequenceItem = newSequenceItem({
    sequenceId: targetSequenceId,
    order: item.order,
  })
  sequenceItems.splice(placement === 'before' ? itemIndex : itemIndex + 1, 0, newItem)
  setSequenceItemsOrder(sequenceItems, item.sequenceId)
}

export function reorderSequenceItems(
  sequenceItems: ContentSequenceItem[],
  rootContentSequenceId: Id,
  oldIndex: number,
  newIndex: number,
) {
  const rootSequenceItems = getSortedSequenceItems(sequenceItems, rootContentSequenceId)
  const item = rootSequenceItems.splice(oldIndex, 1)[0]
  rootSequenceItems.splice(newIndex, 0, item)
  setSequenceItemsOrder(rootSequenceItems)
}

export function deleteSequenceItem(
  sequenceItems: ContentSequenceItem[],
  item: ContentSequenceItem,
  rootContentSequenceId: number,
) {
  const index = sequenceItems.indexOf(item)
  sequenceItems.splice(index, 1)
  setSequenceItemsOrder(getSortedSequenceItems(sequenceItems, rootContentSequenceId))
}

export function copySequence(
  sequenceItems: ContentSequenceItem[],
  copyFrom: ContentSequenceItem,
  rootSequenceId: Id,
) {
  if (copyFrom.nestedSequenceId === null) {
    throw new Error('Cannot copy from a content sequence item that doesn’t have a nested sequence.')
  }

  const newSequenceId = createTemporaryId()
  const copyIndex = sequenceItems.findIndex(({ id }) => id === copyFrom.id)
  const copyItems = sequenceItems.filter(
    ({ sequenceId }) => sequenceId === copyFrom.nestedSequenceId,
  )
  const replaceItems = copyItems.map((item) => ({
    ...item,
    id: createTemporaryId(),
    sequenceId: newSequenceId,
    sourceSequenceId: rootSequenceId,
  }))
  sequenceItems.splice(copyIndex + 1, 0, ...replaceItems)
  for (const item of copyItems) {
    const position = sequenceItems.indexOf(item)
    sequenceItems.splice(position, 1)
  }
  copyFrom.nestedSequenceId = newSequenceId
}

export async function* consumeSequenceItems(
  fetchItems: (sequenceId: Id) => AsyncIterable<ContentSequenceItem>,
  rootSequenceId: Id,
): AsyncIterable<ContentSequenceItem> {
  for await (const item of fetchItems(rootSequenceId)) {
    yield item
    if (item.nestedSequenceId !== null) {
      yield* await consumeSequenceItems(fetchItems, item.nestedSequenceId)
    }
  }
}
