<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    v-if="content"
    class="csb-markdown tw-inline tw-leading-tight tw-break-words tw-prose prose-ul:tw-my-0 prose-ol:tw-my-0 prose-p:tw-mb-1 prose-headings:tw-underline prose-headings:tw-mb-1 prose-headings:tw-mt-2 prose-headings:tw-font-semibold prose-h1:tw-text-xl prose-h2:tw-text-lg prose-h3:tw-text-base prose-h4:tw-font-normal"
    style="hyphens: auto"
    data-csb-markdown
    v-html="renderedContent"
  />
</template>

<script lang="ts" setup>
import snarkdown from 'snarkdown'
import { computed } from 'vue'

const props = defineProps<{
  content: string
}>()

const renderedContent = computed(() => {
  return snarkdown(
    // strip any HTML from the user generated string first,
    // so we don’t introduce any XSS vulnerabilities
    new DOMParser().parseFromString(props.content, 'text/html').body.textContent || '',
  )
    .split(/(?:\r?\n){2,}/)
    .map((l) =>
      [' ', '\t', '#', '-', '*', '>'].some((char) => l.startsWith(char))
        ? snarkdown(l)
        : `<p>${snarkdown(l)}</p>`,
    )
    .join('\n')
})
</script>

<style lang="postcss" scoped>
.csb-markdown :deep(ul + br),
.csb-markdown :deep(ol + br) {
  display: none;
}

.csb-markdown :deep(pre) {
  white-space: pre-wrap;
}
</style>
