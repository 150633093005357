import { ref } from 'vue'

export async function asyncIterableToArray<T>(iterable: AsyncIterable<T>): Promise<T[]> {
  const items = []
  for await (const item of iterable) {
    items.push(item)
  }
  return items
}

export function sortBy<T>(array: T[], key: (item: T) => number): T[] {
  return array.sort((a, b) => key(a) - key(b))
}

export function useLoading(fn: CallableFunction) {
  const isLoading = ref(false)
  const wrappedFn = async (...args: unknown[]) => {
    if (isLoading.value) return

    isLoading.value = true
    try {
      return await fn(...args)
    } finally {
      isLoading.value = false
    }
  }
  return { isLoading, fn: wrappedFn }
}

// Wikipedia says that the average speech tempo is between 90 and 120 words per minute.
// see: https://de.wikipedia.org/wiki/Sprechtempo
export function estimateTimeToSpeak(text: string, wpm = 105) {
  const words = text.trim().split(/\s+/).length
  return (words / wpm) * 60
}

export const unique = <T>(item: T, index: number, items: T[]) => items.indexOf(item) === index
