<template>
  <div>
    <textarea
      v-if="type === 'textarea'"
      v-bind="attrs"
      :id="fieldId"
      ref="textarea"
      v-model="localValue"
      :class="inputClasses"
    ></textarea>
    <input
      v-else
      v-bind="attrs"
      :id="fieldId"
      v-model="localValue"
      :type="type"
      :class="inputClasses"
    />
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
import { computed, inject, ref, useAttrs } from 'vue'
import { useAutoresizeTextarea } from '../util/dom'

const inputClasses = ['tw-w-full', 'tw-border-2', 'tw-border-gray-200', 'tw-rounded', 'tw-p-3']

const attrs = useAttrs()
const props = withDefaults(
  defineProps<{
    modelValue: string
    type?: string
  }>(),
  {
    type: 'text',
  },
)
const fieldId = inject<string>('fieldId')

const emit = defineEmits<{
  (e: 'update:modelValue', id: string): void
}>()

const textarea = ref(null)
useAutoresizeTextarea(textarea)
const localValue = computed({
  get: () => props.modelValue,
  set: (value: string) => {
    emit('update:modelValue', value)
  },
})
</script>
