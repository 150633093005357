import { computed, ref } from 'vue'
import type {
  Audiofile,
  ContentSequence,
  ContentSequenceItem,
  Group,
  Id,
  JSONSerializable,
} from '../types'
import { isTemporaryId } from '../util/content-sequence'
import { getCookie } from '../util/http'
import { asyncIterableToArray } from '../util/misc'

const apiBaseUrl = ref(
  import.meta.env.VITE_LOHROTHEK_API ??
    (typeof location === 'undefined' ? 'http://localhost:8000/api' : `${location.origin}/api`),
)
const csrfToken = ref<string>('')
const fetchCredentials = computed<RequestCredentials | undefined>(() => {
  if (typeof location === 'undefined') {
    return undefined
  } else {
    return new URL(apiBaseUrl.value).origin === window.location.origin ? 'same-origin' : 'include'
  }
})

function renameProperties<T>(
  obj: Record<string, unknown>,
  renameOperations: [string, string][],
): T {
  for (const [oldName, newName] of renameOperations) {
    obj[newName] = obj[oldName]
    delete obj[oldName]
  }
  return obj as T
}

function _fetch(url: string, init?: RequestInit) {
  init = init ?? {}
  init.credentials = fetchCredentials.value
  init.headers = new Headers(init.headers ?? {})
  const csrf = csrfToken.value || getCookie('csrftoken')
  if (csrf) {
    init.headers.set('x-csrftoken', csrf)
  }
  return window.fetch(url, init)
}

function buildQuery(params: Record<string, string | number | undefined | null>) {
  const filteredParams = Object.fromEntries(
    Object.entries(params)
      .filter(([, value]) => typeof value !== 'undefined' && value !== null)
      .map(([key, value]) => [key, String(value)]),
  )
  return new URLSearchParams(filteredParams).toString()
}

const transformAudiofile = (obj: Audiofile) => {
  return renameProperties<Audiofile>(obj, [['reportId', 'groupId']])
}

export function useAPIConfiguration() {
  return { apiBaseUrl, csrfToken }
}

export async function fetchContentSequence(id: Id): Promise<ContentSequence> {
  const response = await _fetch(`${apiBaseUrl.value}/internal/sequences/${id}/`)
  return await response.json()
}

export async function* fetchContentSequenceItems(
  sequenceId: Id,
): AsyncIterable<ContentSequenceItem> {
  let url: string | null = `${apiBaseUrl.value}/internal/sequence-items/?sequence=${sequenceId}`
  while (url) {
    const response = await _fetch(url)
    const { next, results } = (await response.json()) as {
      next: string | null
      results: ContentSequenceItem[]
    }
    yield* results
    url = next
  }
}

export async function fetchAudiofile(id: Id): Promise<Audiofile> {
  const response = await _fetch(`${apiBaseUrl.value}/internal/audiofiles/${id}/`)
  return transformAudiofile(await response.json()) as Audiofile
}

export async function fetchGroup(id: Id): Promise<Group> {
  const response = await _fetch(`${apiBaseUrl.value}/internal/radio-reports/${id}/`)
  return await response.json()
}

export async function* searchAudiofiles(
  term: string,
  options?: { page?: number; pageSize?: number },
): AsyncIterable<Audiofile> {
  const query = buildQuery({ page: options?.page, pageSize: options?.pageSize, search: term })
  const response = await _fetch(`${apiBaseUrl.value}/internal/audiofiles/?${query}`)
  const { results, detail } = await response.json()
  if (!detail) {
    yield* results.map(transformAudiofile)
  }
}

export async function* searchContentSequences(
  term: string,
  filters: { isEmbeddableIn: Id },
  options?: { page?: number; pageSize?: number },
): AsyncIterable<ContentSequence> {
  const query = buildQuery({
    page: options?.page,
    pageSize: options?.pageSize,
    search: term,
    ...filters,
  })
  const response = await _fetch(`${apiBaseUrl.value}/internal/sequences/?${query}`)
  const { results, detail } = await response.json()
  if (!detail) {
    yield* results
  }
}

function sendJSON(
  method: 'POST' | 'PUT' | 'PATCH',
  url: string,
  body: JSONSerializable,
): Promise<Response> {
  return _fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}

export async function saveContentSequenceItems(
  contentSequenceId: Id,
  items: ContentSequenceItem[],
): Promise<void> {
  const baseUrl = `${apiBaseUrl.value}/internal/sequence-items/`
  const existingItems = await asyncIterableToArray(fetchContentSequenceItems(contentSequenceId))
  const requests = []
  const newSequenceMap: Record<Id, ContentSequence> = {}

  async function getOrCreateSequence(temporaryId: Id): Promise<ContentSequence> {
    if (typeof newSequenceMap[temporaryId] === 'undefined') {
      const res = await sendJSON('POST', `${apiBaseUrl.value}/internal/sequences/`, {
        sourceSequenceId: contentSequenceId,
      })
      newSequenceMap[temporaryId] = await res.json()
    }
    return newSequenceMap[temporaryId]
  }

  // create new sequence records for temporary sequences
  // that were created when local copies of them were made
  for (const item of items) {
    if (item.nestedSequenceId && isTemporaryId(item.nestedSequenceId)) {
      item.nestedSequenceId = (await getOrCreateSequence(item.nestedSequenceId)).id
    }
    if (isTemporaryId(item.sequenceId)) {
      item.sequenceId = (await getOrCreateSequence(item.sequenceId)).id
    }
  }

  // handle new and updated elements
  for (const item of items) {
    const [url, method] = isTemporaryId(item.id)
      ? [baseUrl, 'POST']
      : [`${baseUrl}${item.id}/`, 'PUT']

    // prepare data for transmission
    const requestData: Partial<ContentSequenceItem> = { ...item }
    delete requestData.id
    delete requestData.sourceSequenceId

    requests.push(sendJSON(method as 'POST' | 'PUT', url, requestData))
  }

  // handle deleted elements
  for (const existingItem of existingItems) {
    const doesItemStillExist = items.find((item) => item.id === existingItem.id)
    if (!doesItemStillExist) {
      requests.push(_fetch(`${baseUrl}${existingItem.id}/`, { method: 'DELETE' }))
    }
  }

  await Promise.all(requests)
}
