<template>
  <AButton
    round
    size="none"
    flavour="none"
    class="tw-absolute tw-left-1/2 -tw-translate-x-2/4 tw-w-12 tw-h-12 tw-bg-green-100 tw-text-black/75 tw-shadow-lg tw-text-2xl tw-scale-0 tw-z-20 group-hover:tw-scale-100 hover:tw-bg-green-300 hover:tw-shadow-xl"
  >
    <icon-system-uicons-plus />
  </AButton>
</template>
