export type I18N = {
  audio: {
    select: string
    searchHint: string
    searchPlaceholder: string
  }
  builder: {
    save: string
    undo: string
    redo: string
    hideSpeakerTexts: string
    showSpeakerTexts: string
    hideNotes: string
    showNotes: string
  }
  generic: {
    edit: string
    move: string
    loading: string
    noResults: string
    endOfResults: string
    close: string
    deassign: string
    delete: string
  }
  sequence: {
    searchPlaceholder: string
    searchHint: string
    select: string
    selectLabel: string
    deassign: string
  }
  sequenceItem: {
    internalNote: string
    speakersText: string
    relativeStartTime: string
    relativeStartTimeIssue: string
    createCopy: string
    insertFirst: string
    insertBefore: string
    insertAfter: string
  }
}

export const en: I18N = {
  audio: {
    select: 'Select Audiofile',
    searchHint: 'Search for existing audiofiles by title or description.',
    searchPlaceholder: 'e.g. funds',
  },
  builder: {
    save: 'Save',
    undo: 'Undo last change',
    redo: 'Redo last change',
    hideSpeakerTexts: 'Hide speaker texts',
    showSpeakerTexts: 'Show speaker texts',
    hideNotes: 'Hide notes',
    showNotes: 'Show notes',
  },
  generic: {
    edit: 'Edit',
    move: 'Move',
    close: 'Close',
    loading: 'Loading data...',
    noResults: 'No results found',
    endOfResults: 'You’ve reached the end',
    deassign: 'Unassign',
    delete: 'Delete',
  },
  sequence: {
    searchPlaceholder: 'e.g. funds',
    searchHint: 'Search for existing schedules by title or description.',
    select: 'Embed this schedule',
    selectLabel: 'Embed schedule',
    deassign: 'Unassign embedded schedule',
  },
  sequenceItem: {
    internalNote: 'Internal Note',
    speakersText: 'Speaker Text',
    relativeStartTime: 'Relative start time',
    relativeStartTimeIssue:
      'The start time begins earlier than one of the previous items with a defined start time. Please re-order your items or fix the start time. Items with a defined start time are highlighted in purple.',
    createCopy: 'Create Local Copy',
    insertFirst: 'Add first item',
    insertBefore: 'Insert new item before this one',
    insertAfter: 'Insert new item after this one',
  },
}

export const de: I18N = {
  audio: {
    select: 'Audiodatei auswählen',
    searchHint: 'Suche nach Titel oder Beschreibung in existierenden Audiodateien.',
    searchPlaceholder: 'z.B. Gelder',
  },
  builder: {
    save: 'Speichern',
    undo: 'Letzte Änderung zurücknehmen',
    redo: 'Änderung erneut anwenden',
    hideSpeakerTexts: 'Sprecher*innentexte verstecken',
    showSpeakerTexts: 'Sprecher*innentexte anzeigen',
    hideNotes: 'Notizen verstecken',
    showNotes: 'Notizen anzeigen',
  },
  generic: {
    edit: 'Bearbeiten',
    move: 'Verschieben',
    close: 'Schließen',
    loading: 'Lade Daten...',
    noResults: 'Keine Ergebnisse gefunden',
    endOfResults: 'Du hast das Ende erreicht',
    deassign: 'Zuordnung aufheben',
    delete: 'Löschen',
  },
  sequence: {
    searchPlaceholder: 'z.B. Gelder',
    searchHint: 'Suche nach Titel oder Beschreibung in existierenden Sendeplänen.',
    select: 'Diesen Sendeplan einbetten',
    selectLabel: 'Sendeplan einbetten',
    deassign: 'Sendeplaneinbettung entfernen',
  },
  sequenceItem: {
    internalNote: 'Interne Notiz',
    speakersText: 'Sprecher*innentext',
    relativeStartTime: 'Relative Startzeit',
    relativeStartTimeIssue:
      'Die Startzeit liegt vor der eines früheren Eintrags mit festgelegter Zeit. Bitte sortiere die Einträge um oder ändere die Startzeit. Einträge mit festgelegter Startzeit sind in violetter Farbe hervorgehoben.',
    createCopy: 'Lokale Kopie anlegen',
    insertFirst: 'Ersten Eintrag hinzufügen',
    insertBefore: 'Neuen Eintrag vor diesem einfügen',
    insertAfter: 'Neuen Eintrag nach diesem einfügen',
  },
}
