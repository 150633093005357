<template>
  <div class="tw-mb-6 last:tw-mb-0" :data-csb-field="id">
    <template v-if="isEditing === false">
      <slot name="not-editing-pre" />
    </template>
    <header class="tw-flex tw-items-center tw-gap-3">
      <label ref="label" :for="id">
        <slot name="label" />
      </label>
      <AButton
        v-if="isEditing === false && allowEditing"
        round
        size="none"
        class="tw-w-7 tw-h-7 tw-flex-none tw-opacity-0 group-hover:tw-opacity-100 focus-visible:tw-opacity-100"
        :title="i18n.generic.edit"
        @click="enterEditMode"
      >
        <icon-system-uicons-pen />
      </AButton>
    </header>
    <slot v-if="isEditing === undefined || isEditing" :id="id" />
    <div v-if="isEditing === false">
      <slot name="not-editing" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, nextTick, provide, ref } from 'vue'
import type { I18N } from '../i18n'
import AButton from './AButton.vue'

const props = withDefaults(
  defineProps<{
    id: string
    isEditing?: boolean
    allowEditing?: boolean
  }>(),
  { allowEditing: true },
)

const label = ref<HTMLLabelElement>()

async function enterEditMode() {
  emit('edit', true)
  await nextTick()
  label.value?.click()
}

const emit = defineEmits<{
  (e: 'edit', value: boolean): void
}>()

provide<string>('fieldId', props.id)
const i18n = inject<I18N>('i18n') as I18N
</script>
