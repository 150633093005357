<template>
  <Menu as="div" class="tw-relative tw-inline-block tw-text-left" data-csb-menu>
    <div>
      <AButton
        :is="MenuButton"
        :id="menuButtonId"
        round
        size="none"
        flavour="gray-blend"
        class="tw-text-7xl tw-p-2 tw-items-center tw-overflow-hidden"
        data-csb-menubutton
      >
        <icon-system-uicons-menu-vertical
          style="shape-rendering: geometricPrecision"
          aria-hidden="true"
          class="tw-w-8 tw-h-8"
        />
      </AButton>
    </div>

    <transition
      enter-active-class="tw-transition tw-duration-100 tw-ease-out"
      enter-from-class="tw-transform tw-scale-95 tw-opacity-0"
      enter-to-class="tw-transform tw-scale-100 tw-opacity-100"
      leave-active-class="tw-transition tw-duration-75 tw-ease-in"
      leave-from-class="tw-transform tw-scale-100 tw-opacity-100"
      leave-to-class="tw-transform tw-scale-95 tw-opacity-0"
    >
      <MenuItems
        class="tw-absolute tw-right-0 tw-mt-2 tw-w-64 tw-origin-top-right tw-divide-y tw-divide-gray-100 tw-rounded-md tw-bg-white tw-shadow-lg focus:tw-outline-none tw-z-10"
      >
        <div class="tw-p-1">
          <MenuItem v-slot="{ active }">
            <button
              :class="menuItemClasses(active)"
              data-csb-menu-select-audio
              @click="emit('showAudiofileDialog')"
            >
              <icon-system-uicons-audio-wave class="tw-h-5 tw-w-5" aria-hidden="true" />
              {{ i18n.audio.select }}
            </button>
          </MenuItem>
          <MenuItem v-if="allowEmbed" v-slot="{ active }">
            <button
              :class="menuItemClasses(active)"
              data-csb-menu-select-sequence
              @click="emit('showSequenceDialog')"
            >
              <icon-system-uicons-pull-right class="tw-h-5 tw-w-5" aria-hidden="true" />
              {{ i18n.sequence.selectLabel }}
            </button>
          </MenuItem>
          <MenuItem v-else v-slot="{ active }">
            <button
              :class="menuItemClasses(active)"
              data-csb-menu-unassign-sequence
              @click="emit('unassignSchedule')"
            >
              <icon-system-uicons-pull-right class="tw-h-5 tw-w-5" aria-hidden="true" />
              {{ i18n.sequence.deassign }}
            </button>
          </MenuItem>
          <MenuItem v-if="allowCopy" v-slot="{ active }">
            <button
              :class="menuItemClasses(active)"
              data-csb-menu-copy-sequence
              @click="emit('copy')"
            >
              <icon-system-uicons-duplicate class="tw-h-5 tw-w-5" aria-hidden="true" />
              {{ i18n.sequenceItem.createCopy }}
            </button>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button
              :class="menuItemClasses(active, true)"
              data-csb-menu-delete
              @click="emit('delete')"
            >
              <icon-system-uicons-trash class="tw-h-5 tw-w-5" aria-hidden="true" />
              {{ i18n.generic.delete }}
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { inject } from 'vue'
import type { I18N } from '../i18n'
import type { Id } from '../types'
import { useId } from '../util/dom'
import AButton from './AButton.vue'

const props = defineProps<{
  itemId: Id
  allowCopy: boolean
  allowEmbed: boolean
}>()

const emit = defineEmits<{
  (e: 'showAudiofileDialog'): void
  (e: 'showSequenceDialog'): void
  (e: 'unassignSchedule'): void
  (e: 'copy'): void
  (e: 'delete'): void
}>()

const menuButtonId = useId('menu-button', props.itemId)
const menuItemClasses = (isActive: boolean, isDangerous = false) => {
  return [
    'tw-flex tw-gap-2 tw-w-full tw-items-center tw-rounded-md tw-px-2 tw-py-2 tw-text-sm',
    {
      'tw-bg-rose-500 tw-text-white': isActive && isDangerous,
      'tw-bg-gray-200': isActive && !isDangerous,
      'tw-text-gray-900': !isActive,
    },
  ]
}
const i18n = inject<I18N>('i18n') as I18N
</script>
