import { Collection, Group } from '../types'

const collection: Collection<Group> = {
  8: {
    id: 8,
    title: 'Ich höre was, was du nicht hörst mit Mattes',
    description:
      "Redakteur Mattes nimmt uns in diesem Beitrag in seinem Fantasie-Cabrio mit auf eine ganz besondere Reise in ferne Welten im Weltall und wandert dort durch einen Mix aus Großstadt und Dschungel. Neugierig? Na dann: Kopfhörer auf, Augen zu und los geht's!",
  },
}

export default collection
