<template>
  <AField :id="id" :is-editing="true">
    <template #label>
      <ALabel>
        <slot name="label" />
      </ALabel>
    </template>
    <AInput v-model="searchTerm" type="text" :placeholder="texts.placeholder" />
  </AField>

  <div ref="scroller" class="tw-max-h-[600px] tw-overflow-y-scroll tw-overflow-x-hidden">
    <ul v-if="results.length > 0" class="tw-flex tw-flex-col tw-gap-3 tw-max-w-xl">
      <li v-for="item in results" :key="item.id" class="tw-p-6 tw-bg-gray-100 tw-rounded">
        <header class="tw-flex tw-gap-3 tw-items-center tw-mb-3">
          <p class="tw-flex-1">{{ getTitle(item) }}</p>
          <slot name="header-post" :item="item" />
        </header>
        <AMarkdown
          :content="getDescription(item)"
          class="tw-text-sm !tw-block !tw-mb-3 max-w-full break-words"
        />
        <AButton class="tw-bg-white !tw-px-6 !tw-py-2" @click="select(item)">
          {{ texts.selectLabel }}
        </AButton>
      </li>
    </ul>

    <p v-if="isLoading" class="tw-text-center">{{ i18n.generic.loading }}</p>
  </div>

  <footer v-if="!isLoading">
    <p
      v-if="hasReachedEnd"
      class="tw-flex tw-flex-col tw-items-center tw-text-lg tw-font-semibold tw-m-6"
    >
      <template v-if="results.length === 0">
        {{ i18n.generic.noResults }}
        <icon-system-uicons-eye-closed class="tw-w-12 tw-h-12 tw-text-gray-400" />
      </template>
      <template v-else>
        {{ i18n.generic.endOfResults }}
        <icon-system-uicons-trophy class="tw-w-12 tw-h-12 tw-text-yellow-500" />
      </template>
    </p>

    <p v-if="!searchTerm" class="tw-text-gray-600 -tw-mt-4">
      {{ texts.searchHint }}
    </p>
  </footer>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script lang="ts" setup>
import { useDebounce, useInfiniteScroll } from '@vueuse/core'
import { inject, nextTick, ref, watch } from 'vue'

import type { I18N } from '../i18n'
import { API, Id } from '../types'
import { asyncIterableToArray, useLoading } from '../util/misc'
import AButton from './AButton.vue'
import AField from './AField.vue'
import AInput from './AInput.vue'
import ALabel from './ALabel.vue'
import AMarkdown from './AMarkdown.vue'

const PAGE_SIZE = 5

type IdentifiableObject = { id: Id }

const props = defineProps<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getTitle: (item: any) => string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDescription: (item: any) => string
  search: (
    term: string,
    options?: { pageSize?: number; page?: number },
  ) => AsyncIterable<IdentifiableObject>
  texts: {
    placeholder: string
    selectLabel: string
    searchHint: string
  }
  id: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: Id): void
  (e: 'close'): void
}>()

const api = inject<API>('api')

const searchTerm = ref('')
const debouncedSearchTerm = useDebounce(searchTerm, 500)
const results = ref<IdentifiableObject[]>([])
const scroller = ref<HTMLElement>()
const page = ref(1)
const hasReachedEnd = ref(false)

function select(obj: IdentifiableObject) {
  emit('update:modelValue', obj.id)
  emit('close')
}

const { isLoading, fn: loadContent } = useLoading(async function (
  searchTerm: string,
  isNewSearch = false,
) {
  if (api?.searchAudiofiles) {
    if (isNewSearch) {
      results.value = []
      page.value = 1
      if (searchTerm) {
        await nextTick()
        results.value = await asyncIterableToArray(
          await props.search(searchTerm, {
            page: 1,
            pageSize: PAGE_SIZE,
          }),
        )
        hasReachedEnd.value = results.value.length === 0
      }
    } else {
      page.value += 1
      let itemsPushed = 0
      for await (const item of props.search(searchTerm, {
        page: page.value,
        pageSize: PAGE_SIZE,
      })) {
        results.value.push(item)
        itemsPushed += 1
      }
      if (itemsPushed === 0) {
        hasReachedEnd.value = true
      }
    }
  }
})

useInfiniteScroll(scroller, () => loadContent(debouncedSearchTerm.value), { distance: 10 })
watch(debouncedSearchTerm, (searchTerm) => loadContent(searchTerm, true))
watch(searchTerm, (searchTerm) => {
  if (!searchTerm) {
    results.value = []
    page.value = 1
    hasReachedEnd.value = false
  }
})
const i18n = inject<I18N>('i18n') as I18N
</script>
